var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.currentPageType !== "page-settings"
    ? _c(
        "div",
        { staticClass: "d-flex" },
        [
          _vm.showUserMenu
            ? _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "context-menu d-flex align-items-center px-3",
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("b-icon", {
                              staticClass: "mx-1",
                              attrs: { icon: "three-dots" }
                            }),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("more")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2167712747
                  )
                },
                [
                  _vm._l(_vm.customDropdownList, function(item) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: item.name,
                        on: {
                          click: function($event) {
                            return _vm.openLink(item.link)
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(item.name) + " "),
                        _c("b-icon", {
                          attrs: {
                            variant: "dark",
                            icon: item.icon,
                            "aria-hidden": "true"
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _c(
                    "b-dropdown-item",
                    { attrs: { to: "/settings" } },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("buttons.user")) + " "),
                      _c("b-icon", {
                        attrs: {
                          variant: "dark",
                          icon: "gear-fill",
                          "aria-hidden": "true"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.showManagementItems
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/account-management" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("buttons.groupManagement")) +
                              " "
                          ),
                          _c("b-icon", {
                            attrs: {
                              variant: "dark",
                              icon: "people",
                              "aria-hidden": "true"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showManagementItems
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/team-management" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("buttons.teamManagement")) + " "
                          ),
                          _c("b-icon", {
                            attrs: {
                              variant: "dark",
                              icon: "people-fill",
                              "aria-hidden": "true"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showManagementItems
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/personalization-management" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("buttons.personalizationManagement")
                              ) +
                              " "
                          ),
                          _c("b-icon", {
                            attrs: {
                              variant: "dark",
                              icon: "person-check-fill",
                              "aria-hidden": "true"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showManagementItems
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/reports" } },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("buttons.reports")) + " "),
                          _c("b-icon", {
                            attrs: {
                              variant: "dark",
                              icon: "bar-chart-line",
                              "aria-hidden": "true"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isSysAdmin
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/form-management" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("buttons.formManagement")) + " "
                          ),
                          _c("b-icon", {
                            attrs: {
                              variant: "dark",
                              icon: "file-earmark-check",
                              "aria-hidden": "true"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-dropdown-item",
                    { on: { click: _vm.openFeedback } },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("buttons.feedback")) + " "),
                      _c("b-icon", {
                        attrs: {
                          variant: "dark",
                          icon: "chat-dots",
                          "aria-hidden": "true"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    { on: { click: _vm.openHelp } },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("buttons.help")) + " "),
                      _c("b-icon", {
                        attrs: {
                          variant: "dark",
                          icon: "question-circle",
                          "aria-hidden": "true"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    { on: { click: _vm.openPdfCleaner } },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("buttons.pdfCleaner")) + " "),
                      _c("b-icon", {
                        attrs: {
                          variant: "dark",
                          icon: "file-text",
                          "aria-hidden": "true"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    { on: { click: _vm.openCCM19 } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("buttons.cookieBanner")) + " "
                      ),
                      _c("b-icon", {
                        attrs: {
                          variant: "dark",
                          icon: "gear-wide-connected",
                          "aria-hidden": "true"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    { on: { click: _vm.logout } },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("buttons.logout")) + " "),
                      _c("b-icon", {
                        attrs: {
                          variant: "dark",
                          icon: "box-arrow-in-right",
                          "aria-hidden": "true"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            : _c(
                "div",
                {
                  staticClass: "d-flex align-items-center page-navigation px-3"
                },
                [
                  _c(
                    "b-link",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "pr-1",
                      attrs: {
                        to: _vm.prevLink,
                        disabled: _vm.prevLink === null,
                        title: _vm.$t("tooltips.prevArticle")
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          variant: "light",
                          icon: "chevron-left",
                          "aria-hidden": "true"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-link",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "pl-1",
                      attrs: {
                        to: _vm.nextLink,
                        disabled: _vm.nextLink === null,
                        title: _vm.$t("tooltips.nextArticle")
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          variant: "light",
                          icon: "chevron-right",
                          "aria-hidden": "true"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }