<template>
  <div class="d-flex" v-if="this.currentPageType !== 'page-settings'">
    <b-nav-item-dropdown
      v-if="showUserMenu"
      class="context-menu d-flex align-items-center px-3"
      right
    >
      <template v-slot:button-content>
        <b-icon icon="three-dots" class="mx-1"></b-icon>
        <span class="sr-only">more</span>
      </template>
      <b-dropdown-item  v-for="item in customDropdownList" :key="item.name"
        @click="openLink(item.link)">
        {{ item.name }}
        <b-icon variant="dark" :icon="item.icon" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item to="/settings">
        {{ $t('buttons.user') }}
        <b-icon variant="dark" icon="gear-fill" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item
          v-if="showManagementItems"
          to="/account-management"
      >
        {{ $t('buttons.groupManagement') }}
        <b-icon variant="dark" icon="people" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <!--        v-if="$hasPrivileges($privileges('CAN_EDIT_TEAMS'), isSysAdmin, privileges)"-->
      <b-dropdown-item
        v-if="showManagementItems"
        to="/team-management"
      >
        {{ $t('buttons.teamManagement') }}
        <b-icon variant="dark" icon="people-fill" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="showManagementItems"
        to="/personalization-management"
      >
        {{ $t('buttons.personalizationManagement') }}
        <b-icon variant="dark" icon="person-check-fill" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="showManagementItems"
        to="/reports"
      >
      {{ $t('buttons.reports') }}
        <b-icon variant="dark" icon="bar-chart-line" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item
          v-if="isSysAdmin"
          to="/form-management"
      >
        {{ $t('buttons.formManagement') }}
        <b-icon variant="dark" icon="file-earmark-check" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item @click="openFeedback">
        {{ $t('buttons.feedback') }}
        <b-icon variant="dark" icon="chat-dots" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item @click="openHelp">
        {{ $t('buttons.help') }}
        <b-icon variant="dark" icon="question-circle" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item @click="openPdfCleaner">
        {{ $t('buttons.pdfCleaner') }}
        <b-icon variant="dark" icon="file-text" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item @click="openCCM19">
        {{ $t('buttons.cookieBanner') }}
        <b-icon variant="dark" icon="gear-wide-connected" aria-hidden="true"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item @click="logout">
        {{ $t('buttons.logout') }}
        <b-icon variant="dark" icon="box-arrow-in-right" aria-hidden="true"></b-icon>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <div v-else class="d-flex align-items-center page-navigation px-3">
      <b-link
        :to="prevLink"
        :disabled="prevLink === null"
        class="pr-1"
        v-b-tooltip.hover
        :title="$t('tooltips.prevArticle')"
      >
        <b-icon variant="light" icon="chevron-left" aria-hidden="true"></b-icon>
      </b-link>
      <b-link
        :to="nextLink"
        :disabled="nextLink === null"
        class="pl-1"
        v-b-tooltip.hover
        :title="$t('tooltips.nextArticle')"
      >
        <b-icon variant="light" icon="chevron-right" aria-hidden="true"></b-icon>
      </b-link>
    </div>
  </div>
</template>

<script>
import GetPageTypeFromCache from '@/graphQlQueries/queries/getPageTypeFromCache';
import UserAtLeastManagerInOneGroup from '@/graphQlQueries/queries/accessCheckForManagementAreas';
import GetPageNavigationLinksFromCache from '@/graphQlQueries/queries/getPageNavigationLinksFromCache';
import GetGroupSettings from '@/graphQlQueries/queries/getGroupSettings';

export default {
  name: 'HeaderNavigationRight',
  props: {
    privileges: { type: Array, default: null },
    groupManagementPrivileges: { type: Boolean },
    isSysAdmin: { type: Boolean, default: false },
    groupId: {
      type: Number,
    },
  },
  data: () => ({
    currentPageType: null,
    pageNavigationItems: null,
    prevLink: null,
    nextLink: null,
    customDropdownList: null,
    showManagementItems: false,
    blacklistUserMenu: [
      'page',
      'page-settings',
    ],
  }),
  apollo: {
    currentPageType: {
      query: GetPageTypeFromCache,
      update(data) {
        return (data.pageType)
          ? data.pageType.type
          : null;
      },
    },
    pageNavigationItems: {
      query: GetPageNavigationLinksFromCache,
      update(data) {
        this.prevLink = data.pageNavigationLinks.prevLink;
        this.nextLink = data.pageNavigationLinks.nextLink;
      },
    },
    showManagementItems: {
      query: UserAtLeastManagerInOneGroup,
      update(data) {
        return data.checkAccessToManagementAreas;
      },
      fetchPolicy: 'network-only',
    },
    groupSettings: {
      query: GetGroupSettings,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        // this.customDropdownList = [
        //   {
        //     name: 'test',
        //     icon: 'question-circle',
        //     link: 'https://google.de',
        //   },
        //   {
        //     name: 'test2',
        //     icon: 'question-circle',
        //     link: 'https://google.de',
        //   },
        // ];
        this.customDropdownList = (data.groups_by_pk?.settings?.dashboard_dropdown)
          ? data.groups_by_pk.settings.dashboard_dropdown : null;
      },
      skip() {
        return !this.groupId;
      },
      fetchPolicy: 'network-only',
    },
  },
  computed: {
    showUserMenu() {
      return !this.blacklistUserMenu.includes(this.currentPageType);
    },
  },
  methods: {
    openCCM19() {
      window.CCM.openWidget();
      return false;
    },
    openFeedback() {
      // open link in new tab
      const win = window.open(`${this.$i18n.t('links.feedback')}`, '_blank');
      win.focus();
    },
    openHelp() {
      // open link in new tab
      const win = window.open(`${this.$i18n.t('links.help')}`, '_blank');
      win.focus();
    },
    openPdfCleaner() {
      // open link in new tab
      const win = window.open(`${this.$i18n.t('links.pdfCleaner')}`, '_blank');
      win.focus();
    },
    openLink(link) {
      // open link in new tab
      const win = window.open(link, '_blank');
      win.focus();
    },
    async logout() {
      try {
        await this.$router.push('/webmag-logout');
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.b-nav-dropdown, .page-navigation {
  border-left: 1px solid $header-border-color-dark;
}

.page-navigation > a {
  margin: 0 1px;
}

/deep/ .dropdown-menu {
  min-width: inherit;
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}

.page-navigation a svg {
  color: #fff !important;
}

.page-navigation a.disabled svg {
  color: $webmag-grey !important;
}

.page-navigation a:not(.disabled):hover svg {
  color: $webmag-green-light !important;
}
</style>
